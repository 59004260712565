import(/* webpackMode: "eager" */ "/vercel/path0/apps/emser/lib/ui/live-preview/preview-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/emser/lib/ui/theme.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/emser/styles/global.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.6_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-do_d6zooqofkm7ozu22sinir2b3yq/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.6_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_re_ovzaznepe3to4lqamtpmkc5tqi/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@7.1.4_@sanity+client@6.21.3_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.55._tkvema3ck62keuc7ssgw63wyk4/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_babel-plugin-_o4lz2ellwbglqylqwjum7mi3fy/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_babel-plugin-_o4lz2ellwbglqylqwjum7mi3fy/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_babel-plugin-_o4lz2ellwbglqylqwjum7mi3fy/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-nunito-sans\"}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/mui/theme-registry.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/global.scss");
